import React,{useEffect, useState} from "react";
import Layout, { NavBarStatus } from "../components/Layout";
import Hero from "../components/Hero";
import Earning from "../components/Eearning";
import KeyFeatures from "../components/KeyFeatures";
import FlatPart from "../components/FlatPart";
import Friends from "../components/Friends";
import { useNavigate } from "react-router-dom";
import ZohoScript from "../components/ZohoScript";

const Home: React.FC = ()=>{

    const navigate = useNavigate();

    useEffect(()=>{
        console.log("Dashboard useEffect");
        const token = localStorage.getItem("token");
        //if(token == null || token == "")
        //    navigate("/login", {state:{prevPage:"/"}});
      }, []);

    return(
        <Layout>
            <Hero/>
            <Earning/>
            <KeyFeatures />
            <FlatPart />
            <Friends />
            <ZohoScript />
        </Layout>
    );
};

export default Home;